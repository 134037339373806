import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";  

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { PaymentFormComponent } from './pages/payment-form/payment-form.component';
import { ErrorResponseComponent } from './pages/error-response/error-response.component';
import { SuccessResponseComponent } from './pages/success-response/success-response.component';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { MessageService } from './services/message.service';
import { environment } from 'src/environments/environment';
import { BaseUrlInterceptor } from './interceptors/interceptor';
import { PageNotfoundComponent } from './pages/page-notfound/page-notfound.component';
import { PaymentFormAmexComponent } from './pages/payment-form-amex/payment-form-amex.component';
import { OpenpayComponent } from './pages/openpay/openpay.component';
import { OpenpayRedirectComponent } from './pages/openpay-redirect/openpay-redirect.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule  
  ],
  declarations: [
    AppComponent,
    PaymentFormComponent,
    ErrorResponseComponent,
    SuccessResponseComponent,
    PageNotfoundComponent,
    PaymentFormAmexComponent,
    OpenpayComponent,
    OpenpayRedirectComponent
  ],
  providers: [HttpErrorHandler,
    MessageService,
    { provide: "BASE_API_URL", useValue: environment.baseUrl },
    { provide: "BASE_AMEX_URL", useValue: environment.amexUrl},
    { provide: "AMEX_MERCHANT", useValue: environment.amexMerchant},
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },

  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
