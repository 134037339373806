import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Notify, NotifyData } from 'src/app/model/notify';
import { Payment } from 'src/app/model/payment';
import { IpServiceService } from 'src/app/services/ip-service.service';
import { NotifyService } from 'src/app/services/notify.service';
import { environment } from 'src/environments/environment';

declare var SrPago: any;

declare var redirectPage: any;


@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentFormComponent implements OnInit {
  ipAddress: string = '';
  message: string = '';
  isAmex: boolean = false;
  maxlength: number = 3;
  cvv: String = '';
  payment: Payment = new Payment();
  withErrorPromotions=false;
  messagePromotions='';

  promotions:any=[]
  promotion:any;
  hasPromotions:boolean=false;
  loadPromotionsEnabled:boolean=true;
  loadingPromotions:boolean=true;
  isGift:boolean;

  constructor(
    private ipService: IpServiceService,
    private notifyService: NotifyService,
    private router: Router
  ) {}

  ngOnInit() {
    console.log('Payment')
    //console.log("apiKey: "+environment.apiKey)
    //console.log("secureModeProd: "+environment.secureModeProd)
    //console.log("baseUrl: "+environment.baseUrl)
  }
  submitted = false;
  withError = false;

  apiKey = environment.apiKey;
  is3DSecureProduction = environment.secureModeProd;

  verificaCVV() {
    this.message = '';
    this.withError = false;
    
    let cardNumber = document.getElementById('number_span')?.innerHTML;
    let firstDigit = cardNumber?.substring(0, 1);
    console.log(firstDigit)
    if (firstDigit == '3') {
      this.isAmex = true;
      this.maxlength = 4;
    }
    
    
  }

  onSubmit() {
    this.validForm();
    let cvvStr: String = new String(this.cvv);
    console.log(cvvStr)
    if (this.isAmex && cvvStr.length != 4) {
      this.message = '<ul><li>Por favor ingrese 4 dígitos</li></ul>';
      this.submitted = false;
      this.withError = true;
    } else if (!this.isAmex && cvvStr.length != 3) {
      this.message = '<ul><li>Por favor ingrese 3 dígitos</li></ul>';
      this.submitted = false;
      this.withError = true;
    } else if (this.withError == false) {
      this.getIP();
    }
  }

  validForm() {
    this.withError = false;
    let amountStr: string =
      document.getElementById('amount_span')?.innerHTML || '';
    let amount: any = parseFloat(amountStr);
    this.payment.name = document.getElementById('name_span')?.innerHTML || '';
    this.payment.email = document.getElementById('email_span')?.innerHTML || '';
    this.payment.phone = document.getElementById('phone_span')?.innerHTML || '';
    this.payment.street =
      document.getElementById('street_span')?.innerHTML || '';
    this.payment.city = document.getElementById('city_span')?.innerHTML || '';
    this.payment.zipcode =
      document.getElementById('zipcode_span')?.innerHTML || '';
    this.payment.town = document.getElementById('town_span')?.innerHTML || '';
    this.payment.number =
      document.getElementById('number_span')?.innerHTML || '';
    this.payment.expiry_month =
      document.getElementById('expiry_month_span')?.innerHTML || '';
    this.payment.expiry_year =
      document.getElementById('expiry_year_span')?.innerHTML || '';
    this.payment.amount = Number(Math.round(amount * 100) / 100);
    this.payment.token = document.getElementById('token_span')?.innerHTML || '';
    this.payment.idTransaction =
      document.getElementById('idTransaction_span')?.innerHTML || '';
    this.message = '';
    this.isGift =  document.getElementById('isGift')?.innerHTML === "true"?true:false || false;


    if (amount <= 0) {
      this.message += '<li>Amount is required </li>';
    }
    if (this.payment.name.trim() == '') {
      this.message += '<li>Name  is required </li>';
    }

    if (this.payment.email.trim() == '') {
      this.message += '<li>Email is required </li>';
    }

    if (this.payment.phone.trim() == '') {
      this.message += '<li>Phone is required </li>';
    }
    if (this.payment.street.trim() == '') {
      this.message += '<li>Street is required </li>';
    }
    if (this.payment.city.trim() == '') {
      this.message += '<li>City is required</li>';
    }
    if (this.payment.zipcode.trim() == '') {
      this.message += '<li>Zipcode is required </li>';
    }
    if (this.payment.town.trim() == '') {
      this.message += '<li>Town is required </li>';
    }
    if (this.payment.number.trim() == '') {
      this.message += '<li>Card number is required </li>';
    }

    if (this.payment.expiry_month.trim() == '') {
      this.message += '<li>Expiry month is required </li>';
    }

    if (this.payment.expiry_year.trim() == '') {
      this.message += '<li>Expiry year is required </li>';
    }

    if (this.payment.token.trim() == '') {
      this.message += '<li>Token is required </li>';
    }

    if (this.payment.idTransaction.trim() == '') {
      this.message += '<li>Id transaction is required </li>';
    }
    /*if ((this.promotion<=0 || this.promotion == null) && this.promotions.length>0) {
      this.message += '<li>Select a valid promotion </li>';
    }*/
    if (this.message != '') {
      this.withError = true;
      this.message = '<ul>' + this.message + '</ul>';
    }

    return this.withError;
  }

  sendData() {
    let msi = this.promotion == undefined || this.promotion ==null?0:this.promotion
    var data = {
      id: this.payment.token,
      amount: this.payment.amount,
      installments:msi.toString(),
      customer: {
        name: this.payment.name,
        email: this.payment.email,
        phone: this.payment.phone,
        address: {
          street: this.payment.street,
          city: this.payment.city,
          zipcode: this.payment.zipcode,
          town: this.payment.town,
        },
        ip: this.ipAddress,
      },
      card: {
        owner: this.payment.name,
        number: this.payment.number,
        expiry_month: this.payment.expiry_month,
        expiry_year: this.payment.expiry_year,
        cvv: this.cvv + '',
      },
    };
    console.log("PROMOTION")
    console.log(this.promotion)
    if(msi==0){
      console.log('delete installments')
      delete data['installments']
      //data.installments='0'
      let jsonString = JSON.stringify(data);
      data = JSON.parse(jsonString)
    }
    console.log('is3DSecureProduction =' + this.is3DSecureProduction);
    console.log(data);
    this.sendSrPago(data);
  }

  sendSrPago(data: any) {
    SrPago.apiKey = this.apiKey;
    SrPago.isProduction = this.is3DSecureProduction;
    SrPago.Threeds.Logger.setLevel(SrPago.Threeds.Logger.DEBUG);
    console.log('sendSrPago sending SR PAGO')
    //alert('sendSrPago sending SR PAGO =====>' + JSON.stringify(data))
    SrPago.Threeds.pay(data)
      .then((response: any) => {
        console.log('SrPago.Threeds.pay.then ==> ');
        this.sendNotify(
          this.payment.token,
          this.payment.idTransaction,
          response, '/success'
        );
        //redirectPage('/success')
        //this.router.navigate(['/success']);
      })
      .catch((error: any) => {
        console.log('SrPago.Threeds.pay.catch ==> ');
        this.sendNotify(this.payment.token, this.payment.idTransaction, error,
          '/error');
        //redirectPage('/error')
        //this.router.navigate(['/error']);
      });
  }

  loadPromotionsAction(){
    let promotionParam=+document.getElementById('months_span').innerHTML;
    console.log("==>"+ promotionParam)
    if(promotionParam > 0){
      this.loadingPromotions = false;
      this.loadPromotionsEnabled=false;
      this.promotion=promotionParam;
    }else{
      this.loadingPromotions = false;
      this.loadPromotionsEnabled=false;
      /*let cardNumber = document.getElementById('number_span').innerHTML;
      let card_bind = cardNumber.substring(0, 6);
      let amount = document.getElementById('amount_span').innerHTML;
      this.installmentSupperted(card_bind,amount)*/
    }
    
  }

  installmentSupperted(card_bind:any,amount: any) {
    SrPago.apiKey = this.apiKey;
    SrPago.isProduction = this.is3DSecureProduction;
    SrPago.Threeds.Logger.setLevel(SrPago.Threeds.Logger.DEBUG);

    console.log(card_bind)
    console.log(amount)
    SrPago.Utils.Card.installmentsSupported(card_bind, amount)
      .then((response: any) => {
        console.log('SrPago.Threeds.pay.then ==> ');
        console.log(JSON.stringify(response));
        this.promotions=response
        console.log(this.promotions.length)
        console.log(this.promotions)

        if(this.promotions.length==0){
          //this.withErrorPromotions=true;
          //this.messagePromotions='La tarjeta no cuenta con promociones'
          //console.log(this.withErrorPromotions)
          //console.log(this.messagePromotions)
        }
        this.loadingPromotions = false;
        
      })
      .catch((error: any) => {
        console.log('SrPago.Threeds.pay.catch ==> ');
        console.log(JSON.stringify(error));
        this.loadingPromotions = false;
      });
  }

  isValidForm(){
    return this.validForm() || this.loadingPromotions;
  }

  getIP() {
    this.submitted = true;
    this.ipService.getIPAddress().subscribe(
      (res: any) => {
        this.ipAddress = res.ip;
      },
      (error) => {
        this.ipAddress = '0.0.0.0';
      },
      () =>{
        this.sendData();
      }
    );
  }

  sendNotify(token: string, idTransaction: string, response: any, req_response:string) {
    console.log('send Notify');
    let notify_request = this.getPayload(token, idTransaction, response);
    console.log('response srpago'+ JSON.stringify(notify_request));
    console.log('token ==>' +token);
    console.log('idTransaction ==>' +idTransaction);

    //alert('send notifiy ==> processor/notify')
    //alert(JSON.stringify(req_response))

    this.notifyService
      .sendingNotify(notify_request,this.isGift)
      .subscribe((data) => {
        //alert('RESPONSE OK --> '+JSON.stringify(data))
        console.log('Notify response OK==>' + JSON.stringify(data))
        redirectPage(req_response)
      },
      (error)=>{
        console.log('NOTIFY ERROR')
        console.log(JSON.stringify(error))
        //alert('RESPONSE ERROR XXXXX '+JSON.stringify(error))
      },
      () => {
        //redirectPage(req_response)
      });
  }

  getPayload(token: string, idTransaction: string, response: any): Notify {
    let notifyData: NotifyData = new NotifyData(token, idTransaction, response);
    let notify: Notify = new Notify(notifyData);
    return notify;
  }
}
