// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  secureModeProd: true,
  apiKey: 'pk_live_6216b31f3064dTfK8L',
  //apiKey: 'pk_dev_61fc2547a20957v8gt',
  baseUrl: 'http://localhost:7777',
  authUser: 'core-service',
  authPass: 'K33pC4sH@S3cuR3!',
  amexUrl:'http://localhost:8888/',
  amexUsername:'merchant.TEST8482158268',
  amexPassword:'af28c2c5213630aec50955e21d3a7ef5',
  amexMerchant:'TEST8482158268',
  urlOpenPay:'https://dev-external-api.2keepcash.com/chargesOpenpay/',
  openPayEnable:true,
  urlPaymentProccessor: 'https://prod-services.2keepcash.com/'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
