<table align="center" width="100%">
  <tr>
    <td align="center"><img src="./../../../assets/images/frame.svg" class="img-thumbnail"></td>
  </tr>
  <tr>
    <td align="center"> <label for="cvv" style="font-weight: bold;">Pago fallido</label></td>
  </tr>
  <tr>
    <td align="center"><img src="./../../../assets/images/error.svg" class="img-thumbnail"></td>
  </tr>
</table>