import { Component, OnInit } from '@angular/core';
import { Notify, NotifyData } from 'src/app/model/notify';
import { AMEXService } from 'src/app/services/amex-services';
import { NotifyService } from 'src/app/services/notify.service';
import { environment } from 'src/environments/environment';


declare var Checkout: any;
declare function redirectPage(param: string): any;

@Component({
  selector: 'app-payment-form-amex',
  templateUrl: './payment-form-amex.component.html',
  styleUrls: ['./payment-form-amex.component.css']
})
export class PaymentFormAmexComponent implements OnInit {
  message: string = '';
  submitted = false;
  withError = false;

  idOrder = '';
  idTransaction = '';
  successIndicator = '';
  token='';
  isGift:boolean;
  constructor(private notifyService: NotifyService,
    private amexService: AMEXService
  ) { }



  ngOnInit(): void {
    console.log("amexUrl: "+environment.amexUrl)
    console.log("amexUsername: "+environment.amexUsername)
    console.log("amexMerchant: "+environment.amexMerchant)

  }



  onSubmit() {
    Checkout.showEmbeddedPage('#embed-target')
  }

  getOrder(){
    this.idOrder = document.getElementById('idorder_span')?.innerHTML || '';
    this.amexService.getOrder(this.idOrder)
    .subscribe((data) => {
      console.log("RESPONSE_GET_ORDER")
      console.log(JSON.stringify(data))
      let transactions  = data.transaction
      let lengthTransaction = transactions.length;
      let getCurentTransaction = transactions[lengthTransaction-1];
      console.log("=== --> " + getCurentTransaction)
      this.loadDetailTransaction(getCurentTransaction.transaction.id);
    },
      (error) => {
        //sendError
        redirectPage('/error');
      },
      () => {

      });


  }

  loadDetailTransaction(idTransaction:string) {
    this.idOrder = document.getElementById('idorder_span')?.innerHTML || '';
    this.idTransaction = document.getElementById('idtransaction_span')?.innerHTML || '';
    this.successIndicator = document.getElementById('succesIndicator_span')?.innerHTML || '';
    this.token = document.getElementById('token_span')?.innerHTML || '';
    this.isGift = JSON.parse(document.getElementById('isGift')?.innerHTML || '');
    let idTransactionFix = '1'
    this.amexService.getDetailTransaction(this.idOrder, idTransactionFix)

      .subscribe((data) => {
        console.log(JSON.stringify(data))
        this.sendNotify(
          this.token,
          this.idTransaction,
          data
        );
      },
        (error) => {
          this.sendNotify(
            this.token,
            this.idTransaction,
            error
          );
        },
        () => {

        });
  }

  sendNotify(token: string, idTransaction: string, response: any) {
    console.log('send Notify');
    let url = '/error'
    let notify_request = this.getPayload(token, idTransaction, response);
    console.log(JSON.stringify(notify_request));
    this.notifyService
      .sendingNotify(notify_request, this.isGift)
      .subscribe((data) => {
        console.log(data)
        url = '/success'
      },
        (error) => {
          console.log("ERROR NOTIFY")
          console.log(error)
        },
        () => {
          console.log('redirect --->'+ url )
          redirectPage(url)
        });
  }

  getPayload(token: string, idTransaction: string, response: any): Notify {
    let notifyData: NotifyData = new NotifyData(token, idTransaction, response);
    let notify: Notify = new Notify(notifyData);
    return notify;
  }



}
