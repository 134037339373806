import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

    constructor(
        @Inject('BASE_API_URL') private baseUrl: String) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var url_req:String =request?.url 
        
 /*       if(url_req.indexOf('http') == -1 ){
            console.log('other side')
            const apiReq = request.clone({ url: `${this.baseUrl}/${request.url}` });
            return next.handle(apiReq);
        }else{*/

            const apiReq = request.clone({ url: `${request.url}` });
            return next.handle(apiReq);
            
/*        }*/
        
    }
}