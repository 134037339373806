export class Payment {
  public name: string='';
  public email: string='';
  public phone: string='';
  public phoneNumber: string='';
  public street: string='';
  public city: string='';
  public zipcode: string='';
  public town: string='';
  public ip: string='';
  
  public cvv: String='';
  public amount: number=0;
  public token: string='';

  public number: string='';
  public expiry_month: string='';
  public expiry_year: string='';
  public idTransaction: string='';
  public idUser: string='';
  public idBeneficiary: string='';
  public idCard: string='';
  public description: string='';
  public comission:number=0
  public latitud: string='';
  public longitud: string='';
  public cardType: string='';
  public userName: string='';
  public beneficiaryEmail: string='';
  public isForValidCard:boolean=false;
  public isGift:boolean=false;
  public expireData:string;
  public cardNumber:string;
  public userEmail:string
  public isMSI:string;


  public linkTitle:string;
  public linkMessage:string;
  public beneficiary:string;
  public idImage:string;
  public typeToken:string;

  constructor() {

  }
}
