import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import {
  Observable,
  throwError as observableThrowError,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': 'qrUMluWeckIh7AS3Siua6HnPkllxAeB7hS2z48Oh'

  }),
};

@Injectable({
  providedIn: 'root'
})
export class OpenpayService {
  private handleError: HandleError;


  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler,) {
    this.handleError = httpErrorHandler.createHandleError('HeroesService');

  }


  

  getDetailTransaction(id:String): Observable<any> {
    let restBaseUrl=`${environment.urlOpenPay}${id}`
    console.log(restBaseUrl)
    return this.http
      .get<any>(restBaseUrl, httpOptions)
      .pipe(catchError(this.handleError('getDetailTransaction-->')));
  }
}
