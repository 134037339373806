import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Notify, NotifyData } from 'src/app/model/notify';
import { NotifyService } from 'src/app/services/notify.service';
import { OpenpayService } from 'src/app/services/openpay.service';

declare var redirectPage: any;



@Component({
  selector: 'app-openpay-redirect',
  templateUrl: './openpay-redirect.component.html',
  styleUrls: ['./openpay-redirect.component.css']
})
export class OpenpayRedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private openPayService:OpenpayService,
    private notifyService: NotifyService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const id = params.get('id');
      this.loadTransactionDetail(id)
    });

  }


  loadTransactionDetail(id:string){
    this.openPayService
      .getDetailTransaction(id)
      .subscribe((data) => {
        let page = 'success'
        console.log('loadTransactionDetail OK==>' + JSON.stringify(data))
        if(data.message.status !='completed'){
          page ='error'
        }
        //get... localstorage
        let responsePP = JSON.parse(localStorage.getItem("ppResponse") || "") 
        localStorage.removeItem("ppResponse")
        this.sendNotify(responsePP.result.openPayToken, responsePP.result.idTransaction, data,page, responsePP.isGift)
      },
      (error)=>{
        console.log('NOTIFY ERROR')
        console.log(JSON.stringify(error))
        redirectPage('error')
      },
      () => {
      });
  }

  sendNotify(token: string, idTransaction: string, response: any, req_response:string, isGift:boolean) {
    console.log('send Notify');
    let notify_request = this.getPayload(token, idTransaction, response);
    console.log('response srpago'+ JSON.stringify(notify_request));
    console.log('token ==>' +token);
    console.log('idTransaction ==>' +idTransaction);


    this.notifyService
      .sendingNotify(notify_request,isGift)
      .subscribe((data) => {
        console.log('Notify response OK==>' + JSON.stringify(data))
        redirectPage(req_response)

      },
      (error)=>{
        console.log('NOTIFY ERROR')
        console.log(JSON.stringify(error))
        redirectPage('error')
        
      },
      () => {
      });
  }

  getPayload(token: string, idTransaction: string, response: any): Notify {
    let notifyData: NotifyData = new NotifyData(token, idTransaction, response);
    let notify: Notify = new Notify(notifyData);
    return notify;
  }

}
