<form [formGroup]="formGroup">

  <br>
  <div *ngIf="!submitted">
    <br>
    <div class="row">
      <div class="col text-center">

        <table align="center">
          <tr>

            <td colspan="4" align="center">
              <img src="./../../assets/images/frame.svg" class="img-thumbnail">
            </td>
          </tr>
          <tr>
            <td colspan="4" align="center">
              <b><label style="font-weight: bold;">Ingrese el código de seguridad</label></b>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td align="center">

              <input autofocus type="text" id="cvv" class="form-control" title="CVV" alt="CVV" required name="cvv"
                [maxlength]="maxlength" formControlName="cvv"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                style="border-color: #41C5F9;margin-right: 15px;" onkeypress="soloNumeros(event)"
                onblur="javascript:verificarNumerosChange(this);" (keyup)="verificaCVV()" />

            </td>
          </tr>
          <tr>
            <td align="left">
              <div *ngIf="withError" [innerHTML]="message" style="color:red"></div>
            </td>
          </tr>



          <tr>
            <td colspan="4" align="center">
              <button type="submit" (click)="onSubmit()" class="btn btn-success" id="button"
                [disabled]="!formGroup.valid">
                Pagar
              </button>
              <button type="button" id="setterButton" (click)="setValues()" style="display:none;">
                Pagar
              </button>
            </td>
          </tr>

        </table>

        <table align="center" style="width: 100%;">
          <tr>
            <td align="right">
              <div class="logo">Transacciones realizadas vía:</div>
            </td>
            <td align="left">
              <div class="shield">Tus pagos se realizan de forma segura con encriptación de 256 bits</div>
            </td>
          </tr>
        </table>

      </div>

    </div>

  </div>

  <div *ngIf="submitted" style="width: 100%; text-align: center;">
    <img srcset="assets/images/conveyor_1_v1.gif" class="responsive" />
  </div>

  <span id="number_span" style="display:none;"></span>
  <span id="expiry_month_span" style="display:none;"></span>
  <span id="expiry_year_span" style="display:none;"></span>
  <span id="cvv_span" style="display:none;"></span>
  <span id="amount_span" style="display:none;"></span>
  <span id="name_span" style="display:none;"></span>
  <span id="email_span" style="display:none;"></span>
  <span id="phone_span" style="display:none;"></span>
  <span id="street_span" style="display:none;"></span>
  <span id="city_span" style="display:none;"></span>
  <span id="zipcode_span" style="display:none;"></span>
  <span id="town_span" style="display:none;"></span>
  <span id="token_span" style="display:none;"></span>
  <span id="idTransaction_span" style="display:none;"></span>
  <span id="months_span" style="display:none;"></span>
  <span id="isGift" style="display:none;"></span>

  <span id="idUser_span" style="display:none;"></span>
  <span id="idBeneficiary_span" style="display:none;"></span>
  <span id="idCard_span" style="display:none;"></span>
  <span id="description_span" style="display:none;"></span>
  <span id="comission_span" style="display:none;"></span>
  <span id="latitud_span" style="display:none;"></span>
  <span id="longitud_span" style="display:none;"></span>
  <span id="cardType_span" style="display:none;"></span>
  <span id="email_span" style="display:none;"></span>
  <span id="userEmail_span" style="display:none;"></span>

  <span id="userName_span" style="display:none;"></span>
  <span id="beneficiaryEmail_span" style="display:none;"></span>
  <span id="isForValidCard_span" style="display:none;"></span>
  <span id="expiredData_span" style="display:none;"></span>
  <span id="isMSI_span" style="display:none;"></span>

  <span id="linkTitle_span" style="display:none;"></span>
  <span id="linkMessage_span" style="display:none;"></span>
  <span id="beneficiary_span" style="display:none;"></span>
  <span id="idImage_span" style="display:none;"></span>
  <span id="typeToken_span" style="display:none;"></span>
  <span id="cadena_texto" style="display:none;"></span><br>

</form>