<form (ngSubmit)="onSubmit()" #paymentForm="ngForm" id="paymentForm" name="paymentForm">

    <div id="embed-target"> </div> 

    <button type="submit" class="btn btn-success" id="button"
    style="visibility: hidden;">
    Pagar
    </button>

    <button type="button" id="detailTransactionBtn" (click)="getOrder()" style="visibility: hidden;">
    
    
    </button>

    <span id="token_span" style="display:none;"></span>
    <span id="idorder_span" style="display:none;"></span>
    <span id="idtransaction_span" style="display:none;"></span>
    <span id="succesIndicator_span" style="display:none;"></span>
    <span id="isGift" style="display:none;"></span>

   

</form>